/* App.css */
.App {
  font-family: Arial, sans-serif;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;

}

.header {
  display: flex;
  align-items : center;
  justify-content: space-between;
  padding : 5px;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1000; /* Adjust the z-index as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for a raised effect */
  position: fixed;
}

.logo img {
  height: 30px;
}

.nav-links {
  margin : 0px 10px;
  color: #333;
  text-decoration: none;
}

.sign-in {
  background-color: #1a73e8;
  padding : 10px;
  margin-right : 30px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

}


.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Hero Section */
.hero {
  padding: 10px 0;
  text-align: center;
}

.hero-title {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-description {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.get-started {
  background-color: #1a73e8;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 5px;
  cursor: pointer;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 50px 0;
}

.feature {
  max-width: 300px;
  margin-bottom: 30px;
}

.feature img {
  width: 100px;
}

.footer {
  background-color: #f2f2f2;
  padding: 20px;
  margin : 30px;
}

.footer p {
  margin: 0;
}

.footer-nav a {
  margin: 0 10px;
  color: #333;
  text-decoration: none;
}

.animation-container {
  margin: 0px 0;
  padding-top : 80px;

}

.product {
  display: flex;
  align-items: center; /* vertically center content */
  justify-content: space-between; /* ensure space between left and right side */
  padding: 20px;
  border: 1px solid #ddd; /* optional border for better visualization */
  margin: 20px;
}

.products {
  padding : 30px;

}

.left-side, .right-side {
  display: flex;
  flex-direction: column; /* stack elements vertically */
}

.left-side {
  flex: 1; /* take up equal space */
}

.right-side {
  flex: 1; /* take up equal space */
}


.right-side a {
  margin-top: 10px; /* space between description and button */
}
